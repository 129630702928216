import React from 'react';
// Team Logo 
import Team_First from './FixtureResult/Image/Teams/Hyderabad.webp';
import Team_Second from './FixtureResult/Image/Teams/Tamilnadu.webp';
import Team_Third from './FixtureResult/Image/Teams/Kalinga Lancer.webp';
import Team_Fourth from './FixtureResult/Image/Teams/Hyderabad.webp';

import teamA from './FixtureResult/Image/Teams/Tamilnadu.webp';
import teamB from './FixtureResult/Image/Teams/Hyderabad.webp';
import teamC from './FixtureResult/Image/Teams/UP Rudras.webp';
import teamD from './FixtureResult/Image/Teams/SHRACHI.webp';
import teamE from './FixtureResult/Image/Teams/Kalinga Lancer.webp'

const StandingTable = () => {
  const teams = [
    { name: 'Team A', logo: teamA, p: 7, w: 4, ws:1, l: 1, ls:1,  pts: 15 },
    { name: 'Team B', logo: teamB, p: 7, w: 3, ws:2, l: 2, ls:0, pts: 13 },
    { name: 'Team C', logo: teamC, p: 7, w: 4, ws:0, l: 3, ls:0, pts: 12 },
    { name: 'Team D', logo: teamD, p: 6, w: 4, ws:0, l: 2, ls:0,  pts: 12 },
    { name: 'Team E', logo: teamE, p: 7, w: 3, ws:0, l: 3, ls:1, pts: 10 },
    // Add more teams here...
  ];

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white shadow-md rounded-lg">
        <thead className="bg-[#ffbd00] text-white text-center">
          <tr>
            <th className="py-2 px-4 border-b border-gray-200">Team</th>
            <th className="py-2 px-4 border-b border-gray-200">P</th>
            <th className="py-2 px-4 border-b border-gray-200 ">W</th>
            <th className="py-2 px-4 border-b border-gray-200 ">WS</th>
            <th className="py-2 px-4 border-b border-gray-200 ">L</th>
            <th className="py-2 px-4 border-b border-gray-200 ">LS</th>
            <th className="py-2 px-4 border-b border-gray-200 ">PTS</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team, index) => (
            <tr key={index} className="even:bg-gray-50">
              <td className="py-2 px-4  text-gray-800 flex items-center justify-center">
                <img src={team.logo} className='mr-2 w-14 h-auto' alt={`${team.name} logo`} />
              </td>
              <td className="py-2 px-4 font-semibold text-center">{team.p}</td>
              <td className="py-2 px-4 font-semibold text-center">{team.w}</td>
              <td className="py-2 px-4 font-semibold text-center">{team.ws}</td>
              <td className="py-2 px-4 font-semibold text-center">{team.l}</td>
              <td className="py-2 px-4 font-semibold text-center">{team.ls}</td>
              <td className="py-2 px-4 font-semibold text-center">{team.pts}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PointTable = () => {
  return (
    <section className="bg-gray-100 py-6 justify-center items-center mx-auto m-2 mt-10 md:px-[9rem]">
      <main className="md:flex justify-center gap-8 grid">
        {/* Left Section: Fixture & Results */}
        <div className="w-full max-w-lg  rounded-lg p-4">
          <h1 className="text-center text-3xl font-bold my-2 mb-6 text-[#3b0067]">Fixtures & Results</h1>
          <div className="text-center py-1 mb-4 ">
            <div className="flex justify-evenly items-center gap-4 py-2">
              <img src={Team_First} alt="Team 1" className="w-20 h-auto" />
              <span className="text-[#3b0067] text-2xl font-bold">4 - 0 </span>
              <img src={Team_Second} alt="Team 2" className="w-20 h-auto" />
            </div>
            <h2 className="bg-[#3b0067] text-white p-2 rounded-md">
              UP Rudras Vs Hyderabad Toofans
            </h2>
          </div>
          <div className="text-center py-1">
            <div className="flex justify-evenly items-center gap-4 py-2">
              <img src={Team_Third} alt="Team 1" className="w-20 h-auto" />
              <span className="text-[#3b0067] text-2xl font-bold"> 1 - 5 </span>
              <img src={Team_Fourth} alt="Team 2" className="w-20 h-auto" />
            </div>
            <h2 className="bg-[#3b0067] text-white p-2 rounded-md">
              Vedanta Kalinga Lancers Vs Hyderabad Toofans
            </h2>
          </div>
        </div>

        {/* Right Section: Standing Table */}
        <div className="w-full max-w-lg bg-white shadow-md rounded-lg p-4">
          <h1 className="text-center text-3xl font-bold my-2 mb-6 text-[#3b0067]">Standings </h1>
          <StandingTable />
        </div>
      </main>
    </section>
  );
};

export default PointTable;
