import React from 'react'
// import { Link } from 'react-router-dom';

import teamA from './Image/Teams/Tamilnadu.webp';
import teamB from './Image/Teams/Hyderabad.webp';
import teamC from './Image/Teams/UP Rudras.webp';
import teamD from './Image/Teams/SHRACHI.webp';
import teamE from './Image/Teams/Kalinga Lancer.webp';
import teamF from './Image/Teams/JSW.webp';
import teamG from './Image/Teams/Gonasika.webp';
import teamH from './Image/Teams/Delhi SG Pipers.webp';


const StandingTable = () => {
  const teams = [
    { name: 'Team A', logo: teamA, p: 7, w: 4, ws: 1, l: 1, ls: 1, gf:16, ga:15, gd:1, pts: 15 },
    { name: 'Team B', logo: teamB, p: 7, w: 3, ws: 2, l: 2, ls: 0, gf:18, ga:10, gd:8, pts: 13 },
    { name: 'Team C', logo: teamC, p: 7, w: 4, ws: 0, l: 3, ls: 0, gf:13, ga:12, gd:1, pts: 12 },
    { name: 'Team D', logo: teamD, p: 6, w: 4, ws: 0, l: 2, ls: 0, gf:15, ga:15, gd:0, pts: 12 },
    { name: 'Team E', logo: teamE, p: 7, w: 3, ws: 0, l: 3, ls: 1, gf:20, ga:16, gd:4, pts: 10 },
    { name: 'Team F', logo: teamF, p: 6, w: 1, ws: 2, l: 2, ls: 1, gf:9, ga:12, gd:-3, pts: 8 },
    { name: 'Team G', logo: teamG, p: 7, w: 2, ws: 0, l: 4, ls: 1, gf:14, ga:15, gd:-1, pts: 7 },
    { name: 'Team H', logo: teamH, p: 7, w: 0, ws: 1, l: 4, ls: 2, gf:11, ga:21, gd:-10, pts: 4 },
    // Add more teams here...
  ];

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full shadow-md rounded-lg ">
        <thead className="bg-[#ffbd00] text-white text-center">
          <tr>
            <th className="py-1 md:px-4 px-1 border-b border-gray-200 text-center md:text-xl text-sm">Team</th>
            <th className="py-1 md:px-4 px-1 border-b border-gray-200 text-center md:text-xl text-sm">P</th>
            <th className="py-1 md:px-4 px-1 border-b border-gray-200 text-center md:text-xl text-sm">W</th>
            <th className="py-1 md:px-4 px-1 border-b border-gray-200 text-center md:text-xl text-sm">WS</th>  
            <th className="py-1 md:px-4 px-1 border-b border-gray-200 text-center md:text-xl text-sm">L</th>
            <th className="py-1 md:px-4 px-1 border-b border-gray-200 text-center md:text-xl text-sm">LS</th>
            <th className="py-1 md:px-4 px-1 border-b border-gray-200 text-center md:text-xl text-sm">GF</th>
            <th className="py-1 md:px-4 px-1 border-b border-gray-200 text-center md:text-xl text-sm">GA</th>
            <th className="py-1 md:px-4 px-1 border-b border-gray-200 text-center md:text-xl text-sm">GD</th>
            <th className="py-1 md:px-4 px-1 border-b border-gray-200 text-center md:text-xl text-sm">PTS</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team, index) => (
            <tr key={index} className="even:bg-gray-50 ">
              <td className="md:py-2 py-1 md:px-4 px-1  text-gray-800 flex items-center justify-center ">
                <img src={team.logo} className='md:mr-2 w-12 h-auto rounded-full' alt={`${team.name} logo`} />
              </td>
              <td className=" py-1 px-1 font-semibold text-md text-center">{team.p}</td>
              <td className=" py-1 px-1 font-semibold text-md text-center">{team.w}</td>
              <td className=" py-1 px-1 font-semibold text-md text-center">{team.ws}</td>
              <td className=" py-1 px-1 font-semibold text-md text-center">{team.l}</td>
              <td className=" py-1 px-1 font-semibold text-md text-center">{team.ls}</td>
              <td className=" py-1 px-1 font-semibold text-md text-center">{team.gf}</td>
              <td className=" py-1 px-1 font-semibold text-md text-center">{team.ga}</td>
              <td className=" py-1 px-1 font-semibold text-md text-center">{team.gd}</td>
              <td className=" py-1 px-1 font-semibold text-md text-center">{team.pts}</td>
            </tr>
          ))}
          
        </tbody>
        
      </table>
      <p className='pt-2 text-sm'>* P - Played, W - Win, WS - Win with Shootout , L - Loss, LS - Loss with Shootout, GF - Goals For, GA - Goals Against, GD - Goals Difference, Pts - Points</p>
    </div>
  );
};

const ResultTable = () => {
  return (
    <div className='mx-auto m-2 md:px-[13.25rem] mt-14'>
      <div className="w-full bg-white shadow-md rounded-lg py-4">
      <h1 className="text-center text-3xl font-bold my-2 mb-6 text-[#3b0067]">Standings </h1>
        <StandingTable />
      </div>
      {/* <div className='flex justify-center py-2 mt-5'>
        <Link to='/'>
         <button className='text-center flex justify-items-center bg-[#ffbd00] px-6 py-1 rounded-md font-semibold'>View all</button>
      </Link>
      </div> */}
    </div>
    
  )
}

export default ResultTable